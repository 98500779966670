.loading-transaction__portal{
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
}

.loading-transaction__overlay{
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    inset: 0;
}

.loading-transaction__content{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 570px;
    height: 300px;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
    margin: auto auto;
    inset: 0;
}

.loading-transaction__title{
    font-size: large;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
}

.loading-transaction__error-log{
    font-size: 14;
    font-weight: bold;
    color: red
}

.loading-transaction__area-success{
    display: flex;
    flex-direction: column;
    align-items: center;
}